import * as React from 'react';

import { Accordion, AccordionButton, AccordionItem, AccordionPanel } from '@reach/accordion';
import styled from 'styled-components';

import { withListItemRole } from '../../../../utils/withListItemRole';

import { theme } from '../../../../styles/2020/theme';

import { H2, Section } from '../../common/Primitives';
import { Center } from '../../layout/Center';
import { Column } from '../../layout/Column';
import { Icon } from '../../layout/Icon';
import { Row } from '../../layout/Row';

type CollapsibleBlockGroupProps = { items: React.PropsWithChildren<{ title: string }>[] } & {
  maxWidth?: string;
};

export function CollapsibleBlockGroup({ items, maxWidth }: CollapsibleBlockGroupProps) {
  const [indices, setIndices] = React.useState<number[]>([]);

  function toggleItem(toggledIndex: number) {
    if (indices.includes(toggledIndex)) {
      setIndices(indices.filter((currentIndex) => currentIndex !== toggledIndex));
    } else {
      setIndices([...indices, toggledIndex].sort());
    }
  }

  return (
    <Section>
      <Center max={theme.space.siteWidth} intrinsic={true}>
        <AccordionContainer index={indices} onChange={toggleItem} maxWidth={maxWidth}>
          {items.map((item, itemIndex) => (
            <AccordionItem key={item.title}>
              <H2 as="div">
                <AccordionButton style={{ maxWidth: '100%' }}>
                  <Row
                    as="span"
                    justify="space-between"
                    alignSelf="center"
                    style={{ maxWidth: '100%' }}
                  >
                    {item.title}
                    <Icon
                      iconId={indices.includes(itemIndex) ? 'expand-less' : 'expand-more'}
                      iconSize="large"
                      iconColor="grey6"
                    />
                  </Row>
                </AccordionButton>
              </H2>

              <AccordionPanel>
                <Column role="list" space={theme.space.m}>
                  {React.Children.map(item.children, withListItemRole)}
                </Column>
              </AccordionPanel>
            </AccordionItem>
          ))}
        </AccordionContainer>
      </Center>
    </Section>
  );
}

const AccordionContainer = styled(Accordion)<{ maxWidth?: string }>`
  width: 100%;
  ${({ maxWidth }) => maxWidth && `max-width: ${maxWidth};`}

  & [data-reach-accordion-item] {
    border-bottom: ${theme.borders.xs} ${theme.colors.grey3};
  }

  & [data-reach-accordion-button] {
    padding: ${theme.space.s} 0;
    width: 100%;
  }

  & [data-reach-accordion-panel] {
    padding-bottom: ${theme.space.s};
  }
`;
