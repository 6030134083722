import * as React from 'react';

import { add } from 'date-fns';
import { formatInTimeZone, toDate } from 'date-fns-tz';

import type { StileConferencePL, StilePlEvent } from '../../../../types/data';

import { theme } from '../../../../styles/2020/theme';

import { Link } from '../../common/Link';
import { Divider, Separator, Text } from '../../common/Primitives';
import { Column } from '../../layout/Column';
import { Row } from '../../layout/Row';

type EventCategory = 'CONFERENCE' | 'MEETUP' | 'WEBINAR';

type SubEventProps = {
  title: string;
  url: string;
  eventTimes: string;
  location: string;
  description: string;
  cost: string;
};

// NOTE: spacing and color etc should match closely to <JobDetail /> in <JobsListBlock />
export function USEventDetail({
  title,
  category,
  startTime,
  duration,
  timeZone,
  address,
  location,
  url,
  cost,
  description,
  subEvents,
}: StilePlEvent) {
  const utcStartTime = toDate(startTime, { timeZone });
  const utcEndTime = add(utcStartTime, duration);

  const formatDate = (date: Date, timeZone: string, pattern: string) =>
    formatInTimeZone(date, timeZone, pattern);

  const generateConferenceSubEvents = (subevents?: Array<StileConferencePL>) => {
    const arr: JSX.Element[] = []; // REVIEW: why does it make me add the JSX thing, can i replace it with something else?

    if (subevents) {
      subevents.forEach((subevent) => {
        const eventUtcStart = toDate(subevent.startTime, { timeZone });

        const eventTimes = `${formatDate(
          eventUtcStart,
          displayTimeZone,
          'MMMM d, yyyy'
        )} at ${formatDate(eventUtcStart, displayTimeZone, 'p (zzzz)')}`;

        arr.push(
          <SubEvent
            title={subevent.title}
            url={subevent.url}
            eventTimes={eventTimes}
            location={subevent.location}
            description={subevent.description}
            cost={subevent.cost}
          />
        );
      });
    }

    return arr;
  };

  // Set the timezone we want to display event time in, which is currently PST
  const displayTimeZone = 'America/Los_Angeles';

  const eventTitle = `${title}`;
  let eventTimes = `${formatDate(utcStartTime, displayTimeZone, 'p')} – ${formatDate(
    utcEndTime,
    displayTimeZone,
    'p (zzzz)'
  )}`;

  let eventDate = '';
  let eventCategory: EventCategory;
  let linkText = '';

  if (category === 'Conferences') {
    eventCategory = 'CONFERENCE';
    linkText = 'Learn More';

    eventDate = `${formatDate(utcStartTime, timeZone, 'MMMM d, yyyy')} - ${formatDate(
      utcEndTime,
      timeZone,
      'MMMM d, yyyy'
    )}`;
    eventTimes = ''; // We don't want to display a time for a multi-day conference, so override it
  } else if (category === 'Meetups') {
    eventCategory = 'MEETUP';
    linkText = 'Register';

    eventDate = formatDate(utcStartTime, timeZone, 'MMMM d, yyyy');
    linkText = 'Register';
  } else {
    eventCategory = 'WEBINAR';
    linkText = 'Register';

    if (category !== 'Recurring webinars') {
      eventDate = formatDate(utcStartTime, timeZone, 'MMMM d, yyyy');
    } else {
      eventDate = `Every ${formatDate(utcStartTime, timeZone, 'EEEE')}`;
    }
  }

  return (
    <Column space={theme.space.xs}>
      <Row justify="space-between">
        <Column space={theme.space.xxs}>
          <Link openInNewTab url={url}>
            {eventTitle}
          </Link>
          <Row justify="flex-start" space={theme.space.xxxs}>
            <Text size="s" semiBold>
              {eventCategory}
            </Text>

            <Separator />

            <Text size="s" semiBold>
              {eventDate}
            </Text>

            {eventTimes && <Separator />}
            {eventTimes && (
              <Text size="s" semiBold>
                {eventTimes}
              </Text>
            )}

            {eventCategory !== 'CONFERENCE' && <Separator />}
            {eventCategory !== 'CONFERENCE' && (
              <Text size="s" semiBold>
                {cost}
              </Text>
            )}

            {eventCategory === 'CONFERENCE' && <Separator />}
            {eventCategory === 'CONFERENCE' && (
              <Text size="s" semiBold>
                {location}
              </Text>
            )}
          </Row>
          {address && <Text size="s">{address}</Text>}
          {description && (
            <Row>
              <Text size="s">
                {description}{' '}
                <Link openInNewTab url={url}>
                  {linkText}
                </Link>
              </Text>
            </Row>
          )}
          {generateConferenceSubEvents(subEvents)}
        </Column>
      </Row>

      <Divider color="grey3" />
    </Column>
  );
}

function SubEvent({ title, url, eventTimes, location, description, cost }: SubEventProps) {
  return (
    <Column space={theme.space.xxxs}>
      <Text size="sm">
        <Link openInNewTab url={url} variant="grey">
          {title}
        </Link>
      </Text>

      <Row justify="flex-start" space={theme.space.xxxs}>
        <Text size="s" semiBold>
          {eventTimes}
        </Text>

        <Separator />

        <Text size="s" semiBold>
          {location}
        </Text>

        <Separator />

        <Text size="s" semiBold>
          {cost}
        </Text>
      </Row>

      <Text size="s">
        {description}{' '}
        <Link openInNewTab url={url}>
          Register
        </Link>
      </Text>
    </Column>
  );
}
