import * as React from 'react';

import { add } from 'date-fns';
import { format, formatInTimeZone, toDate } from 'date-fns-tz';

import type { StilePlEvent } from '../../../../types/data';

import { theme } from '../../../../styles/2020/theme';

import { ButtonLink } from '../../common/ButtonLink';
import { Link } from '../../common/Link';
import { Divider, Separator, Text } from '../../common/Primitives';
import { Column } from '../../layout/Column';
import { Row } from '../../layout/Row';

// NOTE: spacing and color etc should match closely to <JobDetail /> in <JobsListBlock />
export function EventDetail({
  startTime,
  duration,
  location,
  timeZone,
  address,
  url,
  cost,
}: StilePlEvent) {
  const utcStartTime = toDate(startTime, { timeZone });
  const utcEndTime = add(utcStartTime, duration);
  const isVirtual = location.toLowerCase() === 'virtual';

  // 1. Virtual events should display time in the user's local time with user's locale offset.
  // E.g. Melbourne event + Melbourne user -> 9am - 3pm (AEST)
  // E.g. Melbourne event + European user -> 1am - 7am (CEST)
  // 2. Everything else should display time that is local to the event location/timeZone,
  // with an offset that is local to the user viewing the site.
  // E.g. Melbourne event + Melbourne user -> 9am - 3pm (AEST)
  // E.g. Melbourne event + European user -> 9am - 3pm (GMT+10)
  const formatDate = (date: Date, pattern: string) =>
    isVirtual ? format(date, pattern) : formatInTimeZone(date, timeZone, pattern);

  const eventDate = formatDate(utcStartTime, 'do MMMM');
  const eventTitle = `${eventDate} — ${location}`;

  const eventTimes = `${formatDate(utcStartTime, 'p')} – ${formatDate(utcEndTime, 'p (zzz)')}`;

  return (
    <Column space={theme.space.xs}>
      <Row justify="space-between">
        {/* Should mirror JobDetail layout */}
        <Column space={theme.space.xxs}>
          <Link openInNewTab url={url}>
            {eventTitle}
          </Link>

          <Row justify="flex-start" space={theme.space.xxxs}>
            <Text size="s" semiBold>
              {eventTimes}
            </Text>

            <Separator />

            <Text size="s" semiBold>
              {cost}
            </Text>
          </Row>

          {address && <Text size="s">{address}</Text>}
        </Column>

        <ButtonLink openInNewTab variant="secondary" url={url}>
          Register
        </ButtonLink>
      </Row>

      <Divider color="grey3" />
    </Column>
  );
}
