import * as React from 'react';

import type { StilePlEvent } from '../../../../types/data';

import { getFilteredListings } from '../../../../utils/getFilteredListings';
import { hasEventEnded } from '../../../../utils/hasEventEnded';

import { FilteredListings } from '../../common/FilteredListings';
import { P } from '../../common/Primitives';
import { Select } from '../../common/Select';

import { EventDetail } from './EventDetail';
import { USEventDetail } from './USEventDetail';

export type EventBlockVariant = 'AU' | 'US';

type EventsListBlockProps = {
  title?: string;
  items: StilePlEvent[];
  category?: string;
  location?: string;
  variant?: EventBlockVariant;
  sidebarText?: string | React.ReactNode;
};

function useEvents(items: StilePlEvent[], variant: EventBlockVariant) {
  const futureEvents = items.filter(
    (item) => (item.isWeekly || !hasEventEnded(item)) && item.market === variant
  );

  const categories = [
    'All categories',
    ...Array.from(new Set(futureEvents.map((item) => item.category))),
  ];

  const locations = [
    'All locations',
    ...Array.from(new Set(futureEvents.map((item) => item.location))),
  ];

  return {
    events: futureEvents,
    categories,
    locations,
    // we're not remotely fetching events
    isLoading: false,
    isError: false,
  };
}

export function EventsListBlock({
  title = 'Upcoming events',
  category = 'All categories',
  location = 'All locations',
  variant = 'AU',
  items,
  sidebarText,
}: EventsListBlockProps) {
  const [filterCategory, setFilterCategory] = React.useState(category);
  const [filterLocation, setFilterLocation] = React.useState(location);

  const { events, categories, locations, isLoading, isError } = useEvents(items, variant);

  const listings = getFilteredListings<StilePlEvent>(
    events,
    categories,
    filterCategory,
    filterLocation,
    'category',
    'location',
    variant,
    'market'
  );

  const locationOptions = locations.map((item) => ({
    value: item,
    text: item,
  }));

  const categoryOptions = listings.map((item) => ({
    value: item.slug,
    text: `${item.slug} (${item.count})`,
  }));

  const onLocationSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setFilterLocation(e.target.value);
  };

  const onCategorySelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setFilterCategory(e.target.value);
  };

  const filters = (
    <>
      <Select options={locationOptions} onChange={onLocationSelect} />
      <Select options={categoryOptions} onChange={onCategorySelect} />
    </>
  );

  const errorMessage =
    variant === 'US' ? (
      <P>
        You’ve just missed our first events for {new Date().getFullYear()}. But don’t fret, we’ll
        have more coming up soon!
      </P>
    ) : (
      <P>
        We don’t seem to have any events running at the moment. Adjust your filter selections or
        check back at a later date.
      </P>
    );

  return (
    <FilteredListings
      title={title}
      filters={filters}
      errorMessage={errorMessage}
      isLoading={isLoading}
      isError={isError}
      listings={listings}
      ListingComponent={variant === 'US' ? USEventDetail : EventDetail}
      fullWidth
      sidebarText={sidebarText}
    />
  );
}
