import { Duration, add, isAfter } from 'date-fns';
import { toDate } from 'date-fns-tz';

import { FlashPlEvent, StilePlEvent } from '../types/data';

export function hasEventEnded(
  { startTime, timeZone, duration }: StilePlEvent | FlashPlEvent,
  options: { gracePeriod?: Duration } = {}
) {
  // Only consider event ended after gracePeriod has also expired
  const { gracePeriod } = { ...options, gracePeriod: { hours: 1 } };
  const utcEventEndTime = add(toDate(startTime, { timeZone }), duration);
  const utcNow = new Date(Date.now());

  return isAfter(utcNow, add(utcEventEndTime, gracePeriod));
}
