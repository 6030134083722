import type { StileJobDetailType, StilePlEvent } from '../types/data';

export type FilteredListing<Items = (StilePlEvent | StileJobDetailType)[]> = {
  slug: string;
  items: Items;
  count: number;
  active?: boolean;
};

export function getFilteredListings<Item = StilePlEvent | StileJobDetailType>(
  allItems: Item[],
  categories: string[],
  filterCategory: string,
  filterLocation: string,
  itemCategoryKey: keyof Item,
  itemLocationKey: keyof Item,
  filterMarket?: string,
  itemMarketKey?: keyof Item
) {
  const filteredListings: FilteredListing<Item[]>[] = [];

  for (const category of categories) {
    if (category === 'All categories') {
      filteredListings.push({
        slug: category,
        items: allItems,
        count: allItems.length,
      });
    } else {
      const itemsByCategory = allItems.filter((item) => {
        const itemCategory = item[itemCategoryKey];

        let itemBelongsToMarket = true;

        if (filterMarket && itemMarketKey) {
          const itemMarket = item[itemMarketKey];
          itemBelongsToMarket = typeof itemMarket === 'string' && itemMarket === filterMarket;
        }

        return typeof itemCategory === 'string' && itemCategory === category && itemBelongsToMarket;
      });

      if (itemsByCategory.length > 0) {
        filteredListings.push({
          slug: category,
          items: itemsByCategory,
          count: itemsByCategory.length,
          active: filterCategory === category || filterCategory === 'All categories',
        });
      }
    }
  }

  if (filterMarket && itemMarketKey) {
    for (const item of filteredListings) {
      const itemsByMarket = item.items.filter((item) => {
        const itemMarket = item[itemMarketKey];
        return typeof itemMarket === 'string' && itemMarket === filterMarket;
      });

      item.items = itemsByMarket;
      item.count = itemsByMarket.length;
    }
  }

  if (filterLocation !== 'All locations') {
    for (const item of filteredListings) {
      const itemsByLocation = item.items.filter((item) => {
        const itemLocation = item[itemLocationKey];
        return typeof itemLocation === 'string' && itemLocation === filterLocation;
      });

      item.items = itemsByLocation;
      item.count = itemsByLocation.length;
    }
  }

  return filteredListings;
}
