import * as React from 'react';

import type { PageProps } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import styled from 'styled-components';

import { CollapsibleBlockGroup } from 'stile-shared/src/components/2020/blocks/CollapsibleBlockGroup';
import { CtaBlock } from 'stile-shared/src/components/2020/blocks/CtaBlock';
import { EventsListBlock } from 'stile-shared/src/components/2020/blocks/EventsListBlock';
import { HeroBlock } from 'stile-shared/src/components/2020/blocks/HeroBlock';
import { TextBlock } from 'stile-shared/src/components/2020/blocks/TextBlock';
import { ButtonLink } from 'stile-shared/src/components/2020/common/ButtonLink';
import { Link } from 'stile-shared/src/components/2020/common/Link';
import { H5, P, Text, Ul } from 'stile-shared/src/components/2020/common/Primitives';
import { CONTACT } from 'stile-shared/src/constants';

import { getPageData } from 'utils/getPageData';

import { theme } from 'styles/theme';

import { PageLayout } from 'templates/PageLayout';

import { NextPageBlock } from 'components/blocks/NextPageBlock';

import { stilePlEventsUS } from 'data/events-us';

export { Head } from 'templates/Head';

function StilePlPage(props: PageProps) {
  const { hero } = getPageData(props.location.pathname);

  const accordionChildren = [
    {
      title: 'Past Events',
      children: [
        <Text key={1}>
          Find out where we’ve been out and about in the science education community.
        </Text>,
        <Text key={2}>
          <H5 as="p">Conferences</H5>
          We absolutely love attending conferences and bringing fun, informative workshops and happy
          hour events with us! Learn more about where we’ve been recently:
          <Ul style={{ maxWidth: 'none' }}>
            <ListItem>
              <strong>The Colorado Charter Schools Annual Conference</strong> February 29 - March 1
              2023, Westminister, Colorado
            </ListItem>

            <ListItem>
              <strong>Michigan Science Teachers Association (MSTA) Conference</strong> March 3-4,
              2023, Lansing, Michigan
            </ListItem>

            <ListItem>
              <strong>California Charter Schools Conference</strong> March 13-16, 2023, Sacramento,
              California
            </ListItem>

            <ListItem>
              <strong>National Science Teachers Association (NSTA) Conference</strong> March 22-25,
              2023, Atlanta, Georgia
            </ListItem>

            <ListItem>
              <strong>Nevada State Science Teachers Association (NSSTA) Conference</strong> May 20,
              2023, Reno, Nevada
            </ListItem>

            <ListItem>
              <strong>International Society for Technology in Education (ISTE) Conference</strong>{' '}
              June 25-28, 2023, Philadeplphia, Pennsylvania
            </ListItem>

            <ListItem>
              <strong>Oregon Science Teachers Association (OSTA) Conference</strong> October 13-14,
              2023, Gresham, Oregon
            </ListItem>
          </Ul>
        </Text>,
        <Text key={3}>
          <H5 as="p">PD Workshops</H5>
          We take our professional learning sessions on the road! They’re completely free to
          teachers, and every event includes food, drinks and opportunities for science nerds and
          educators to chat, mingle and learn with each other! Find out more about the PD workshops
          we’ve run in the past:
        </Text>,
        <Text key={4}>
          <strong>
            Easy, breezy SEPeezy: Three tips to get your students thinking, talking, and acting like
            scientists and engineers
          </strong>
          <br />
          We hosted this PD on April 19th, 2023 at Elephant Room, Detroit, Michigan and on April
          20th, 2023 at Hamlin Pub, Detroit, Michigan.
          <br />
          The key takeaways from this workshop:
          <Ul style={{ maxWidth: 'none' }}>
            <ListItem>
              Using a scaffold, like a conversation menu, helps students to articulate what SEPs
              mean to them
            </ListItem>

            <ListItem>
              Explicitly modeling actions that demonstrate SEPs means students can recognize what
              success looks like and take action
            </ListItem>

            <ListItem>
              Breaking down and identifying SEPs in an assessment will help teachers to measure
              success and differentiate
            </ListItem>
          </Ul>
        </Text>,
        <Text key={5}>
          <strong>
            Brave enough to fail: Three strategies for building student resilience around analyzing
            data
          </strong>
          <br />
          We hosted this PD on April 24th, 2023 at The Blind Burro, San Diego, California, on April
          26th, 2023 at Jackrabbit Brewing, Sacramento, California and on April 27th, 2023 at Camino
          Brewing, San Jose, California.
          <br />
          The key takeaways from this workshop:
          <Ul style={{ maxWidth: 'none' }}>
            <ListItem>
              Lowering the stakes will support students to adopt a growth mindset when approaching
              data
            </ListItem>

            <ListItem>
              Providing relevant context around data that students care about will support
              engagement
            </ListItem>

            <ListItem>
              Breaking down graphs, creating checklists, and providing model answers will reduce
              cognitive load and support long-term learning
            </ListItem>
          </Ul>
        </Text>,
      ],
    },
    {
      title: 'Past Webinars',
      children: [
        <Text key={1}>Looking for more? Take a look at our previous webinars!</Text>,
        <Text key={2}>
          <strong>Unleash the Power of ChatGPT for Effective Teacher Planning</strong>
          <br />
          Discover the secrets of turning the planning process into an effortless and engaging
          experience with ChatGPT. During this session, you will learn practical strategies that are
          ready to use and will empower you to plan like a pro.
        </Text>,
        <ButtonLink
          key={3}
          openInNewTab
          url="https://stileeducation.zoom.us/rec/share/47Ha9l2x0mUKCBA30hH_sDFg0AZEsSkFFlPb7NdqP3o69LYDIVPtzB9TGhcB7tda.XO84xOFshpDjVD4q"
        >
          Access Recording
        </ButtonLink>,
        <Text key={4}>
          <strong>Reengage Students with Interactive Science Lessons</strong>
          <br />
          Science education is crucial for preparing students for the rapidly changing world they
          will inherit. Explore Stile’s fun, interactive science resources will help reengage your
          students after the holiday break, including how to run an escape room and customize our
          science lessons to meet the needs and interests of your students.
        </Text>,
        <ButtonLink
          key={5}
          openInNewTab
          url="https://stileeducation.zoom.us/rec/share/BoA3dasvIERz36q6S6B2JYt6gtkT8Bpy2GoPVQ2sq6ZZWwHMD7ZYaCUXTrgPiEgS.1Tl62SDUdfkRXhBW"
        >
          Access Recording
        </ButtonLink>,
      ],
    },
  ];

  return (
    <PageLayout {...props}>
      <HeroBlock
        {...hero}
        image={
          <StaticImage
            loading="eager"
            aspectRatio={2.37}
            src="../../../../stile-shared/assets/images/us-events/cover_us_events.png"
            alt="A speaker giving a professional learning talk to a seated crowd"
          />
        }
      />
      <TextBlock space={theme.space.l}>
        <P>
          We love nothing more than getting out into our community and sharing our passion for
          quality science education. At a Stile event, you can always expect a laugh, to get into a
          discussion of best practice science teaching and to walk away with something new that you
          can use in the classroom the very next day.
        </P>
        <P>
          From free webinars to happy hour events, we’ve got your science fix covered! Teachers can
          swap tips at our meet-ups, and our conference events are always a blast. Join us for a fun
          and educational experience that’s sure to broaden your horizons and leave you with a
          smile!
        </P>
        <P>Take a look at the next event you can find us at, we’d love to meet you!</P>
      </TextBlock>

      <EventsListBlock
        items={stilePlEventsUS}
        title="Upcoming events"
        category="All categories"
        location="All locations"
        variant="US"
      />

      {/* The width of this sidebar should match it to the Events List Block above, when both are at their widest */}
      <CollapsibleBlockGroup items={accordionChildren} maxWidth="970px" />

      <TextBlock space={theme.space.l}>
        <P>
          Want Stile to host an event for your teacher in your area?{' '}
          <Link openInNewTab url={CONTACT.email.community.url}>
            Get in touch!
          </Link>
        </P>
      </TextBlock>

      <NextPageBlock path="/why-choose-stile/evidence-based-pedagogy/" />

      <CtaBlock />
    </PageLayout>
  );
}

export default StilePlPage;

const ListItem = styled.li`
  max-width: none;
`;
