import { v4 as uuidv4 } from 'uuid';

import type { StilePlEvent } from 'stile-shared/src/types/data';

export const stilePlEventsUS: StilePlEvent[] = [
  // --- HOW TO ADD EVENTS ---
  //
  //    The provided information is used slightly differently based on which market the event will be available to (AU or US), however, both require the same fields.
  //
  //    REQUIRED FIELDS:
  //      category: the category of event this is. in the US this is 'Recurring Webinars', 'Special Webinars', 'Meetups' or 'Conference'. In Aus this will be something like 'Level Up Professional Learning'
  //      location: the location of the event. either 'Virtual' or 'City, State'
  //      market: either 'AU' or 'US'. determines which version of the marketing site it will show up on
  //      startTime: the time and date the event starts at. format: 'YYYY-MM-DDTHH:MM:SS' OR 'YYYY-MM-DD' for conferences
  //      duration: the duration of the event as an object. for example: { hours: 1, minutes: 30 }
  //      timeZone: the local timezone of the event, such as 'Australia/Melbourne'. can be found using https://en.wikipedia.org/wiki/List_of_tz_database_time_zones
  //      url: the link to book/buy tickets
  //      cost: how much the ticket will cost, can be left as an empty string ('') for conferences
  //
  //    OPTIONAL FIELDS:
  //      title: the title of the event, only displayed on the US site
  //      address: the address the event will occur at, for in-person events
  //      description: additional information about the event, only displayed on the US site
  //      isWeekly: if this event will repeat every week. if so, set to true WITHOUT quotation marks, and set startTime to the first occurrence
  //      subEvents: used for US conferences, this allows sub-events to be provided. these require a title, url, startTime, location, description and cost to be provided
  //
  //    EXAMPLE:
  //      {
  //        title: 'Nevada State Science Teachers Association (NSSTA) Conference',
  //        category: 'Conferences',
  //        location: 'Reno, Nevada',
  //        market: 'US',
  //        startTime: '2023-05-20',
  //        timeZone: 'America/Los_Angeles',
  //        duration: { days: 1 },
  //        url: 'https://www.nvscience.org/Events',
  //        cost: '',
  //        subEvents: [
  //          {
  //            title: 'Brave Enough to Fail',
  //            url: 'https://www.eventbrite.com.au',
  //            startTime: '2023-05-20T16:00:00',
  //            location: 'A313, Georgia World Congress Center',
  //            description:
  //              'Join our fun, hands-on workshop where we’ll impart our top three tips to get your students thinking, talking, and acting like scientists and engineers. This event includes food, drinks and opportunities to chat and mingle with fellow science nerds and educators!',
  //            cost: 'Free',
  //          },
  //        ],
  //      },
  //

  // AU EVENTS
  // See file: packages/stile-au/data/events-au.ts

  // US EVENTS
  {
    title: 'Oregon Science Teacher Mixer - Lake Oswego',
    category: 'Meetups',
    location: 'Lake Oswego',
    market: 'US',
    startTime: '2023-10-30T16:00:00', // local time for given timezone
    timeZone: 'America/Los_Angeles',
    duration: { hours: 2 },
    url: 'https://www.eventbrite.com/e/oregon-science-teacher-mixer-tickets-739752939957?aff=oddtdtcreator',
    cost: '',
  },
  {
    title: 'Oregon Science Teacher Mixer - Tillamook',
    category: 'Meetups',
    location: 'Tillamook',
    market: 'US',
    startTime: '2023-11-02T16:00:00', // local time for given timezone
    timeZone: 'America/Los_Angeles',
    duration: { hours: 2 },
    url: 'https://www.eventbrite.com/e/oregon-science-teacher-mixer-tickets-740583042817?aff=oddtdtcreator',
    cost: '',
  },
  {
    title: 'Oregon Science Teacher Mixer - Seaside',
    category: 'Meetups',
    location: 'Seaside',
    market: 'US',
    startTime: '2023-11-03T16:00:00', // local time for given timezone
    timeZone: 'America/Los_Angeles',
    duration: { hours: 2 },
    url: 'https://www.eventbrite.com/e/oregon-science-teacher-mixer-tickets-740593845127?aff=oddtdtcreator',
    cost: '',
  },
  {
    title: 'Oregon Science Teacher Mixer - Beaverton',
    category: 'Meetups',
    location: 'Beaverton',
    market: 'US',
    startTime: '2023-11-06T16:00:00', // local time for given timezone
    timeZone: 'America/Los_Angeles',
    duration: { hours: 2 },
    url: 'https://www.eventbrite.com/e/oregon-science-teacher-mixer-tickets-740595469987?aff=oddtdtcreator',
    cost: '',
  },
  {
    title: 'Oregon Science Teacher Mixer - Corvallis',
    category: 'Meetups',
    location: 'Corvallis',
    market: 'US',
    startTime: '2023-11-07T16:00:00', // local time for given timezone
    timeZone: 'America/Los_Angeles',
    duration: { hours: 2 },
    url: 'https://www.eventbrite.com/e/oregon-science-teacher-mixer-tickets-741035435937?aff=oddtdtcreator',
    cost: '',
  },
  {
    title: 'Oregon Science Teacher Mixer - Salem',
    category: 'Meetups',
    location: 'Salem',
    market: 'US',
    startTime: '2023-11-08T16:00:00', // local time for given timezone
    timeZone: 'America/Los_Angeles',
    duration: { hours: 2 },
    url: 'https://www.eventbrite.com/e/oregon-science-teacher-mixer-tickets-741044432847?aff=oddtdtcreator',
    cost: '',
  },
  {
    title: 'Oregon Science Teacher Mixer - Eugene',
    category: 'Meetups',
    location: 'Eugene',
    market: 'US',
    startTime: '2023-11-09T16:00:00', // local time for given timezone
    timeZone: 'America/Los_Angeles',
    duration: { hours: 2 },
    url: 'https://www.eventbrite.com/e/oregon-science-teacher-mixer-tickets-741049758777?aff=oddtdtcreator',
    cost: '',
  },
  {
    title: 'Oregon Science Teacher Mixer - Lincoln City',
    category: 'Meetups',
    location: 'Lincoln City',
    market: 'US',
    startTime: '2023-11-10T16:00:00', // local time for given timezone
    timeZone: 'America/Los_Angeles',
    duration: { hours: 2 },
    url: 'https://www.eventbrite.com/e/oregon-science-teacher-mixer-tickets-741062908107?aff=oddtdtcreator',
    cost: '',
  },
  {
    title: 'Oregon Science Teacher Mixer - Hood River',
    category: 'Meetups',
    location: 'Hood River',
    market: 'US',
    startTime: '2023-11-13T16:00:00', // local time for given timezone
    timeZone: 'America/Los_Angeles',
    duration: { hours: 2 },
    url: 'https://www.eventbrite.com/e/oregon-science-teacher-mixer-tickets-741068093617?aff=oddtdtcreator',
    cost: '',
  },
  {
    title: 'Oregon Science Teacher Mixer - Bend',
    category: 'Meetups',
    location: 'Bend',
    market: 'US',
    startTime: '2023-11-14T16:00:00', // local time for given timezone
    timeZone: 'America/Los_Angeles',
    duration: { hours: 2 },
    url: 'https://www.eventbrite.com/e/oregon-science-teacher-mixer-tickets-741069858897?aff=oddtdtcreator',
    cost: '',
  },
  {
    title: 'Oregon Science Teacher Mixer - Medford',
    category: 'Meetups',
    location: 'Medford',
    market: 'US',
    startTime: '2023-11-16T16:00:00', // local time for given timezone
    timeZone: 'America/Los_Angeles',
    duration: { hours: 2 },
    url: 'https://www.eventbrite.com/e/oregon-science-teacher-mixer-tickets-741071443637?aff=oddtdtcreator',
    cost: '',
  },
].map((item) => ({
  ...item,
  shortcode: uuidv4(),
}));
